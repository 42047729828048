'use client';

import Button from '@/components/ui/Button';
import Heading from '@/components/ui/Heading';
import Text from '@/components/ui/Text';
import * as Sentry from '@sentry/nextjs';
import Image from 'next/image';
import {useEffect} from 'react';
import NextLink from 'next/link';
import Link from '@/components/ui/Link';
import Container from '@/components/ui/Container';

export default function GlobalError({
  error,
}: {
  error: Error & {digest?: string};
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <html>
      <body>
        <div className="h-screen content-center">
          <Container>
            <div className="flex flex-col gap-4 md:gap-6 lg:flex-row lg:justify-between lg:items-center">
              <div className="flex flex-col gap-8 md:gap-8 lg:max-w-[642px]">
                <div className="flex flex-col gap-3">
                  <Heading size="h2" as="h1" weight="light" family="brand">
                    Something went wrong...
                  </Heading>
                  <Text weight="light" family="brand">
                    The application has encountered an error. We suggest you
                    return to the homepage and try again. If the problem
                    persists, feel free to contact our support team for
                    assistance.
                  </Text>
                </div>
                <div>
                  <Button asChild>
                    <NextLink href="/">Return home</NextLink>
                  </Button>{' '}
                  <Text>or</Text>{' '}
                  <Link asChild underline variant="monochrome">
                    <NextLink href="/contact-us">Contact support</NextLink>
                  </Link>
                </div>
              </div>
              <Image
                className="w-full max-w-60 aspect-[233/164] md:max-w-[60%] lg:w-1/2 lg:max-[614px]"
                src="/global-error.svg"
                alt=""
                width={233}
                height={165}
                unoptimized
              />
            </div>
          </Container>
        </div>
      </body>
    </html>
  );
}
